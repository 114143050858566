/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/htmx.org@1.9.10/dist/htmx.min.js
 * - /npm/htmx.org@1.9.10/dist/ext/multi-swap.js
 * - /npm/htmx.org@1.9.10/dist/ext/head-support.js
 * - /npm/alpinejs@3.14.7/dist/cdn.min.js
 * - /npm/js-loading-overlay@1.1.0/dist/js-loading-overlay.min.js
 * - /npm/fallbackjs@1.1.8/fallback.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
